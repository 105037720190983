// import MainPagetitle from '../../layouts/MainPagetitle';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Encategory } from '../Common/Enum';
import CategoryService from '../api/CategoryService';



const CategorySearch = ({ isRefresh }) => {
    const [searchData, setsearchData] = useState([]);
    //#region Search
    const [searchFilter, setsearchFilter] = useState({
        CategoryType: '-1'
        , CategoryCode: ''
        , CompanyID: 1
    });

    useEffect(() => {
        CategoryService.CategorySearch(searchFilter)
            .then((response) => {

                setsearchData(response.data);

            })
            .catch((error) => {
                console.error("Error fetching category search:", error);
            });


    }, [isRefresh]);
    const CategorySearch = (e) => {
        e.stopPropagation();
        e.preventDefault();

        CategoryService.CategorySearch(searchFilter)
            .then((response) => {
                debugger
                // console.success("category search successfully", success);
                setsearchData(response.data);

            })
            .catch((error) => {
                console.error("Error fetching category search:", error);
            });

    };
    //#endregion
    function handleChange(evt) {

        const name = evt.target.name;
        let value = evt.target.value;
        setsearchFilter({
            ...searchFilter,
            [evt.target.name]: value,
        });
    }

    return (
        <>
            <div className="card">
                <div className='col-lg-12 p-3 row'>
                    <div className="form-group col-lg-3">
                        <label > Category Type<span className="text-danger">*</span>
                        </label>
                        <select className="border default-select dropdown-item p-2"
                            id="CategoryType"
                            name="CategoryType"
                            value={searchFilter.CategoryType}
                            onChange={handleChange}
                        >
                            <option value="-1">Select All</option>
                            {Encategory.map((item) => (
                                <option key={item.label}>{item.value}</option>
                            ))}

                        </select>
                    </div>
                    <div className="form-group col-lg-3">
                        <label >CategoryCode<span className="text-danger">*</span>
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="CategoryCode"
                            name="CategoryCode"
                            value={searchFilter.CategoryCode}
                            onChange={handleChange}
                            placeholder="Category Code" />
                    </div>
                    <div className="form-group col-lg-1">
                        <label></label>
                        <button type="submit" className="btn btn-primary mb-3" onClick={CategorySearch}>Search</button>
                    </div>
                </div>

                <div className="card-body p-0">
                    <div
                        className="table-active table-responsive"
                        style={{ overflowX: "auto", maxHeight: "400px" }}
                    >
                        <div className="table-responsive active-projects manage-client">
                            <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">


                            </div>
                            <div id="finance-tblwrapper" className="dataTables_wrapper no-footer">
                                <table id="empoloyees-tbl1" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                    <thead>
                                        <tr>
                                            <th>Category Type</th>
                                            <th>Category Name</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {searchData.map((item, index) => (
                                            <tr key={item.CategoryKey}>
                                                <td>
                                                    <Link to={"/CategoryEntry/" + item.CategoryKey} className="text-primary">{item.CategoryType}</Link>
                                                </td>
                                                <td>
                                                    <span>{item.CategoryName}</span>
                                                </td>
                                                <td>
                                                    <span>{item.CategoryStatus === true ? "Active" : "Inctive"}</span>
                                                </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CategorySearch;